import { useEffect, useState, useContext } from "react";
import { ApiContext, UserContext } from "../../context";
import { useParams, useSearchParams, useLocation, Link } from "react-router-dom";
import TrackList from "../../components/TrackList";
import { SmallCardCarousel } from "../../components/MusicSections/SmallCardCarousel";

function PlaylistView() {
  const [playlist, setPlaylist] = useState({} as any);
  const [exploreData, setExploreData] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);

  const stagedive = useContext(ApiContext);

  const { id } = useParams();

  const getPageData = async (playlistId) => {
    setIsLoading(true);
    try {
      const playlist = await stagedive.getPlaylist(playlistId);
      document.title = playlist.name;
      setPlaylist(playlist);
    } catch (error: any) {
      setShowError(true);
    }

    try {
      const randomTracks = await stagedive.getRandomTracks();
      setExploreData(randomTracks);
    } catch (error) {}

    setIsLoading(false);
  };

  useEffect(() => {
    getPageData(id);

    return () => {
      document.title = "StageDive";
    };
  }, []);

  if (isLoading) {
    return (
      <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
    );
  } else if (!isLoading && showError) {
    return (
      <section className="p-10">
        <div className="text-center" style={{ marginBottom: "12.5rem" }}>
          <h1 className="text-3xl mb-4">Playlist not found</h1>
          <p className="text-lg">The requested playlist could not be found.</p>
        </div>

        <SmallCardCarousel title="Explore" tracks={exploreData} />
      </section>
    );
  } else {
    return (
      <>
        <section className="p-10">
          <h1 className="text-3xl mb-1 ml-1">{playlist.name}</h1>
          <h3 className="text-lg mb-5 ml-1"><Link to={`/user/${playlist.user.id}`} className="hover:underline">{`${playlist.user.displayName}`}</Link>{`'s Playlist`}</h3>
          <p className="text-md mb-5 ml-1">{`${playlist.tracks.length} track(s) `}</p>

          <TrackList tracks={playlist.tracks} />

          <SmallCardCarousel title="Explore" tracks={exploreData} />
        </section>
      </>
    );
  }
}

export default PlaylistView;
