import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../Carousel";
import { usePlaylistContext } from "../../context/PlaylistContext"; //TO MOVE
import MusicCard from "../MusicCard/MusicCard";
import { PlayIcon } from "@radix-ui/react-icons";
import { SkeletonCardHorizontal } from "../../components/MusicSections";

interface ListViewCarouselProps {
  title: string;
  tracks: Track[];
  playlistID?: string;
}

interface Card {
  id: string;
  albumArt: string;
  artist: string;
  songName: string;
}

const ListViewCarousel: React.FC<ListViewCarouselProps> = ({
  title,
  tracks,
}: ListViewCarouselProps) => {
  const { clearAndLoadNewPlaylist } = usePlaylistContext();
  const [dividedTracksArray, setDividedTracksArray] = useState<
    ListViewCarouselProps["tracks"][] | null[]
  >([null, null, null, null, null, null]);

  useEffect(() => {
    // divides the tracks array into subarrays with a length of 3
    if (tracks.length > 0) {
      let subArrays: ListViewCarouselProps["tracks"][] = []; // Array to hold all subarrays
      let subArray: ListViewCarouselProps["tracks"] = []; // Temporary subarray

      tracks.forEach((track, index) => {
        subArray.push(track);
        // Check if subArray has 3 elements or if it's the last track
        if (subArray.length === 4 || index === tracks.length - 1) {
          subArrays.push(subArray);
          subArray = []; // Reset the subArray after pushing its content
        }
      });

      // Update the state with the new array of subarrays
      setDividedTracksArray(subArrays);
    }
  }, [tracks]);

  return (
    <div className="music-section">
      <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0 text-primary">
        {title}
        {tracks.length > 0 ? (
          <PlayIcon
            onClick={() => {
              clearAndLoadNewPlaylist(tracks, 0);
            }}
            className="w-8 h-8 inline border rounded-full p-1 ml-6 text-[#ffe561]/75  cursor-pointer hover:text-[#ffe561] hover:border-[#ffe561] hover:bg-secondary/75 fill-white"
          />
        ) : null}
      </h2>
      <Carousel
        opts={{
          align: "start",
        }}
        className="w-full "
      >
        <div className="w-full flex items-center justify-end gap-4">
          <CarouselPrevious />
          <CarouselNext />
        </div>
        {dividedTracksArray[0] !== null ? (
          <CarouselContent className="">
            {dividedTracksArray.map((trackArray, index) => (
              <CarouselItem
                key={index}
                className=" md:basis-1/2 lg:basis-1/3 flex flex-col gap-2"
              >
                {/* MusicCards vertical */}
                {trackArray.map((track, trackIndex) => (
                  <MusicCard
                    trackArray={tracks}
                    index={tracks.findIndex((t) => t.id === track.id)}
                    track={track}
                    variant={"vertical"}
                  />
                ))}
              </CarouselItem>
            ))}
          </CarouselContent>
        ) : (
          <CarouselContent>
            {dividedTracksArray.map((fill, index) => (
              <CarouselItem
                key={index}
                className=" md:basis-1/2 lg:basis-1/3 flex flex-col gap-2"
              >
                <SkeletonCardHorizontal />
              </CarouselItem>
            ))}
          </CarouselContent>
        )}
      </Carousel>
    </div>
  );
};

export { ListViewCarousel };
