import { useContext, useRef, useState } from "react";
import { useScreen } from "usehooks-ts";
import { Link } from "react-router-dom";
import { ApiContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import Modal from "../Modal";

const SearchBar = () => {
  const inputRef = useRef<any>(null);
  const stagedive = useContext(ApiContext);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [showModal, setShowModal] = useState(false);
  const screen = useScreen();

  const isMobile = () => {
    if (!screen) return false;
    if (screen.width < 650) return true;
    else return false;
  };

  const debounce = (func, delay) => {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        //@ts-ignore
        func.apply(this, args);
      }, delay);
    };
  };

  const handleChange = async (e) => {
    const searchTerm = e.target.value;
    // Don't send to API if all whitespace
    if (!/\S/.test(searchTerm)) {
      setSearchResults([]);
    } else {
      try {
        const count = 7;
        const searchResults = await stagedive.search(e.target.value, count);
        setSearchResults(searchResults);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSearchResults([]);
  };

  /*
  useOnClickOutside(inputRef, () => {
    setSearchResults([]);
  });
  */

  return (
    <>
      {isMobile() ? (
        <div>
          <button
            className="btn normal-case mr-3"
            onClick={() => setShowModal(true)}
          >
            Search
          </button>
          <Modal
            open={showModal}
            backgroundColor="bg-neutral-900"
            onClose={() => setShowModal(!showModal)}
            parentClass="h-[100vh]"
          >
            <div className="h-screen">
              <SearchInput
                onChange={debounce(handleChange, 300)}
                searchResults={searchResults}
                inputRef={inputRef}
                isMobile={isMobile()}
                handleClose={handleClose}
              />
            </div>
          </Modal>
        </div>
      ) : (
        <SearchInput
          onChange={debounce(handleChange, 300)}
          searchResults={searchResults}
          inputRef={inputRef}
          isMobile={isMobile()}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const SearchInput = ({
  onChange,
  searchResults,
  inputRef,
  isMobile,
  handleClose,
}) => {
  const stagedive = useContext(ApiContext);
  const { playNewTrack } = usePlaylistContext();

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Search..."
        className="bg-white text-black focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full leading-normal"
        onChange={onChange}
        ref={inputRef}
      />
      <div className="absolute right-0 top-0 bottom-0 mr-4 flex items-center">
        <span className="material-symbols-rounded" style={{ color: "black" }}>
          arrow_right_alt
        </span>
      </div>
      {searchResults.length > 0 && (
        <ul className="absolute p-1 px-1 bg-neutral-900 w-full rounded-lg list-none">
          {searchResults.map(({ type, document: { name, id } }) => (
            <li
              className="transition duration-300 ease-in-out hover:bg-neutral-500 px-2 pt-2 pb-1 cursor-pointer"
              key={type + id}
              onClick={handleClose}
            >
              {type === "Album" ? (
                <Link to={`/album/${id}`}>
                  <p className="my-0">{name}</p>
                  <p className="my-0 text-xs">{type}</p>
                </Link>
              ) : type === "Creator" ? (
                <Link to={`/creator/${id}`}>
                  <p className="my-0">{name}</p>
                  <p className="my-0 text-xs">{type}</p>
                </Link>
              ) : (
                <div>
                  <Link
                    to={`/track/${id}`}
                    onClick={async () => {
                      let track = await stagedive.getTrack(id);
                      playNewTrack(track);
                    }}
                  >
                    <p className="my-0">{name}</p>
                    <p className="my-0 text-xs">{type}</p>
                  </Link>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
      {isMobile && searchResults.length === 0 && (
        <p className="text-center">Search for tracks, albums, and creators</p>
      )}
    </div>
  );
};

export default SearchBar;
