import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./Browse.css";

function Browse() {
  let colors = [
    'bg-red-700/75',
    'bg-orange-500/75',
    'bg-lime-600/75',
    'bg-sky-600/75',
    'bg-fuchsia-800/75',
  ]

  let genres = [
    { id: 'Rock', text: 'Rock'},
    { id: 'Rap', text: 'Rap'},
    { id: 'Alternative Rock', text: 'Alt. Rock'},
    { id: 'Podcast', text: 'Podcasts'},
    { id: 'Country', text: 'Country'},
    { id: 'Electronica', text: 'Electronic'},
    { id: 'Jazz', text: 'Jazz'},
    { id: 'Indie', text: 'Indie'},
    { id: 'Hip Hop', text: 'Hip-Hop'},
    { id: 'Funk', text: 'Funk'},
    { id: 'Metal', text: 'Metal'},
    { id: 'Swing', text: 'Swing'},
    { id: 'Experimental', text: 'Experimental'},
    { id: 'Punk', text: 'Punk'},
    { id: 'Folk', text: 'Folk'},
    { id: 'Classical', text: 'Classical'},
    { id: 'Pop', text: 'Pop'},
    { id: 'Techno', text: 'Techno'},
    { id: 'Ambient', text: 'Ambient'},
    { id: 'Afrobeats', text: 'Afrobeats'},
    { id: 'World', text: 'World'},
    { id: 'R&B', text: 'R&B'},
    { id: 'EDM', text: 'EDM'},
    { id: 'Blues', text: 'Blues'},
    { id: 'Reggae', text: 'Reggae'},
    { id: 'Soul', text: 'Soul'},
  ];
  return (
    <div className="p-10">
      <h1 className="text-primary text-center pb-10">Browse</h1>
      
      <div className="grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 max-w-screen-lg mx-auto">
        {genres.map((genre, index) => (
          <Link to={"./tag/?id=" + encodeURIComponent(genre.id)} className={"text-center py-6 " + colors[index % 5]} key={index}>
            <p className="text-primary text-lg uppercase">{genre.text}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Browse;
