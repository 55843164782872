//Provider created to manage state globally and share it across different components.
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import Hls from "hls.js";
import StageDiveAPI from "../api";

interface PlaylistProviderProps {
  children: ReactNode;
}

interface PlaylistContextProps {
  playlistData: Track[];
  setPlaylistData: React.Dispatch<React.SetStateAction<Track[]>>;
  currentSongIndex: number;
  setCurrentSongIndex: React.Dispatch<React.SetStateAction<number>>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  autoplayNext: boolean;
  setAutoplayNext: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currentTime: number;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  isHLSInitialized: boolean;
  setIsHLSInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  videoRef: React.RefObject<HTMLVideoElement>;
  playNewTrack: React.Dispatch<Track>;
  clearAndLoadNewPlaylist: (tracks: Track[], index: number) => Promise<void>;
  canStream: boolean;
  setCanStream: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLiked: React.Dispatch<React.SetStateAction<boolean>>;
  isLiked: boolean;
  setLikeForTrack: React.Dispatch<any>;
  isSubscribed: boolean;
  setIsSubscribed: React.Dispatch<any>;
}

const PlaylistContext = createContext<PlaylistContextProps | undefined>(
  undefined
);

export const PlaylistProvider: React.FC<PlaylistProviderProps> = ({
  children,
}) => {
  //Playlist is an array of Tracks
  const [playlistData, setPlaylistData] = useState<Track[]>(() => {
    const storedData = localStorage.getItem("playlistData");
    return storedData ? JSON.parse(storedData) : [];
  });

  //Index of the Track array
  const [currentSongIndex, setCurrentSongIndex] = useState<number>(() => {
    const storedIndex = localStorage.getItem("currentSongIndex");
    return storedIndex ? parseInt(storedIndex, 10) : 0;
  });

  const [currentTime, setCurrentTime] = useState<number>(() => {
    const storedTime = localStorage.getItem("currentTime");
    return storedTime ? parseFloat(storedTime) : 0;
  });

  //State management for subscription status
  const [isSubscribed, setIsSubscribed] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("isSubscribed");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    localStorage.setItem("playlistData", JSON.stringify(playlistData));
  }, [playlistData]);

  useEffect(() => {
    localStorage.setItem("currentSongIndex", currentSongIndex.toString());
  }, [currentSongIndex]);

  useEffect(() => {
    localStorage.setItem("currentTime", currentTime.toString());
  }, [currentTime]);

  useEffect(() => {
    localStorage.setItem("isSubscribed", JSON.stringify(isSubscribed));
  }, [isSubscribed]);

  //Function to toggle subscription
  const toggleSubscription = () => {
    setIsSubscribed((prevState) => !prevState);
  };

  const [isPlaying, setIsPlaying] = useState<boolean>(false); //State if the Track is playing
  const [autoplayNext, setAutoplayNext] = useState<boolean>(false); //Autoplays the track
  const [isLoading, setIsLoading] = useState<boolean>(false); //State to load the track
  const [duration, setDuration] = useState<number>(0); // Track total duration of the song
  const [isHLSInitialized, setIsHLSInitialized] = useState<boolean>(false); //Adding to fix the first song load delay
  const [volume, setVolume] = useState<number>(0.5); //Volume control - Default 50%
  const videoRef = useRef<HTMLVideoElement | null>(null); // Create a videoRef
  const [canStream, setCanStream] = useState<boolean>(false); //State if user can stream
  const [isLiked, setIsLiked] = useState(false);

  const stagedive = new StageDiveAPI();

  const setLikeForTrack = async (track) => {
    try {
      const trackLikes = await stagedive.getTrackLikes(track.id);
      const liked = trackLikes.length === 1;
      setIsLiked(liked);
    } catch (error) {
      console.error(error);
    }
  };

  //This method will pause the current song, add the selected song next in the playlist queue & play the song
  const playNewTrack = async (track) => {
    if (canStream) {
      // Add the selected track to the next position in the playlist
      const updatedPlaylist = JSON.parse(JSON.stringify(playlistData)); // Copy the data
      updatedPlaylist.splice(currentSongIndex + 1, 0, track); // Insert the new track data into the copy
      setPlaylistData(updatedPlaylist); // Update state

      // The newly added track will be at currentSongIndex + 1
      const newTrackIndex = currentSongIndex + 1;

      // Pause current playback and reset video source
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        videoRef.current.src = "";
      }

      // Update the current song index to the newly added track
      setCurrentSongIndex(newTrackIndex);
      await setLikeForTrack(track);

      // Update local storage
      localStorage.setItem("currentSongIndex", newTrackIndex.toString());
      localStorage.setItem("playlistData", JSON.stringify(updatedPlaylist));

      // Pause Playback
      setIsPlaying(false);

      // Wait for the videoRef to be ready before setting source and playing
      if (videoRef.current) {
        const hls = new Hls({
          xhrSetup: (xhr, url) => {
            xhr.withCredentials = true;
          },
        });
        hls.loadSource(track.url);
        hls.attachMedia(videoRef.current);

        setCurrentTime(0);
        setIsPlaying(true);

        // Resume playback with autoplay
        setAutoplayNext(true);
      }
    }
  };

  //This method will clear the current playlist and load a new playlist
  //Adding index to the clear and load
  const clearAndLoadNewPlaylist = async (tracks, index) => {
    if (canStream) {
      // Pause current playback and reset video source
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        videoRef.current.src = "";
      }
      setIsPlaying(false);
      // Clear the current playlist
      setPlaylistData([]);
      setCurrentSongIndex(index);
      setAutoplayNext(true);

      setDuration(0);

      //Instead of passing in a playlist id pass in an array of tracks, then it will always work
      try {
        setPlaylistData(tracks);
        // Update local storage
        localStorage.setItem("playlistData", JSON.stringify(tracks));
        localStorage.setItem("currentSongIndex", index.toString());
        // Wait for the videoRef to be ready before setting source and playing
        if (videoRef.current && Hls.isSupported()) {
          const hls = new Hls({
            xhrSetup: (xhr, url) => {
              xhr.withCredentials = true;
            },
          });
          hls.loadSource(tracks[index].url);
          hls.attachMedia(videoRef.current);
          await setLikeForTrack(tracks[index]);

          videoRef.current!.onloadedmetadata = () => {
            setCurrentTime(0);
            setDuration(videoRef.current!.duration);
            setIsPlaying(true); // Start playback
          };
        } else if (
          videoRef.current?.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoRef.current.src = tracks[index].url;
          setIsLoading(false);
          // Autoplay or start playback here if needed
          if (autoplayNext) {
            videoRef.current.play().catch((error) => {
              console.error("Autoplay failed:", error);
            });
          }
        }
      } catch (error) {
        console.error((error as Error).message);
      }
    }
  };

  const contextValues: PlaylistContextProps = {
    playlistData,
    setPlaylistData,
    currentSongIndex,
    setCurrentSongIndex,
    isPlaying,
    setIsPlaying,
    autoplayNext,
    setAutoplayNext,
    isLoading,
    setIsLoading,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    isHLSInitialized,
    setIsHLSInitialized,
    volume,
    setVolume,
    videoRef,
    playNewTrack,
    clearAndLoadNewPlaylist,
    canStream,
    setCanStream,
    setIsLiked,
    isLiked,
    setLikeForTrack,
    isSubscribed,
    setIsSubscribed,
  };

  return (
    <PlaylistContext.Provider value={contextValues}>
      {children}
    </PlaylistContext.Provider>
  );
};

export const usePlaylistContext = () => {
  const context = useContext(PlaylistContext);
  if (!context) {
    throw new Error(
      "usePlaylistContext must be used within a PlaylistProvider"
    );
  }
  return context;
};
