import { useContext, useMemo, useState } from "react";

import countryList from "react-select-country-list";

import Modal from "../../../../../../components/Modal";
import { ApiContext } from "../../../../../../context";

type Props = {
  open;
  handleToggle;
  error;
};

const MonetizationModal = ({ open, handleToggle, error }: Props) => {
  const stagedive = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState(false);

  const redirectToStripeAccountLink = (country) => {
    if (country === "") return;

    setIsLoading(true);
    try {
      stagedive.createStripeAccountLink(country).then((link) => {
        window.location.href = link.url;
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (event) => {
    setCountry(event.target.value);
  };

  return (
    <Modal open={open} onClose={handleToggle}>
      <h3 className="font-bold text-lg">Monetization</h3>

      <p>Select the country that you reside in:</p>

      <select
        className="text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-zinc-700 border-zinc-700 placeholder-zinc-400 text-white"
        value={country}
        onChange={changeHandler}
      >
        {options.map(({ value, label }) => (
          <option value={value}>{label}</option>
        ))}
      </select>

      <button
        className="btn normal-case mr-3 mt-3 block"
        onClick={() => redirectToStripeAccountLink(country)}
        disabled={isLoading}
      >
        Continue
      </button>

      {error && (
        <div className="alert alert-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error}</span>
        </div>
      )}
    </Modal>
  );
};

export default MonetizationModal;
