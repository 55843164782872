import React, { useEffect, useState, useContext } from 'react';
import { ApiContext, UserContext } from "../../context";
import Modal from "../Modal";
import ProfileSubscription from "../../pages/Profile/components/ProfileSubscription";

type Props = {
  open;
  handleToggle;
  isLoading;
  error;
};

const SubscriptionModal = ({ open, handleToggle, isLoading, error }: Props) => {
  
    const { user } = useContext(UserContext);
    const stagedive = useContext(ApiContext);
    const [subscriptions, setSubscriptions] = useState([] as any);
    const getSubscriptions = async () => {
        try {
            if (user.id) {
                const subscriptions = await stagedive.getUserSubscriptions(user.id);
                setSubscriptions(subscriptions);
            }
        } catch (error) {
            console.error(error);
        }
    };

	useEffect(() => {
		getSubscriptions();
	}, [user]);

  	return (
		<>
		{/* Only load this modal if user is logged in and doesn't have a subscription */}
		{user.id && subscriptions.length == 0 && (
			<Modal open={open} onClose={handleToggle}>
				<ProfileSubscription></ProfileSubscription>
		
				{error && (
					<div className="alert alert-error">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="stroke-current shrink-0 h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
						>
							<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
						<span>{error}</span>
					</div>
				)}
			</Modal>
		)}
		</>
  	);
};

export default SubscriptionModal;
