import { Stripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

interface CheckoutFormProps {
  stripe: Promise<Stripe | null>;
  clientSecret: string | null;
}

const CheckoutForm = ({ stripe, clientSecret }: CheckoutFormProps) => {
  const options = {
    clientSecret,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripe} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
