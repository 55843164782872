import { useErrorHandler } from "../../../../context/ErrorHandler";

type Props = {
  user;
  editable: boolean;
  onClickEditProfile: () => void;
  showFollowingModal: () => void;
};

const UserBanner = ({
  user,
  editable,
  onClickEditProfile,
  showFollowingModal,
}: Props) => {
  const { handleError } = useErrorHandler();

  const copyURL = () => {
    try {
      const copyText = `${window.location.host}/user/${user.id}`;
      navigator.clipboard.writeText(copyText);
    } catch (err) {
      handleError(err);
    }
  };

  const displayName = user.displayName.split("@")[0];

  return (
    <div className="flex flex-wrap flex-nowrap items-center bg-zinc-900 p-5 rounded-lg shadow">
      {/* Profile Image */}
      <div className="flex justify-center lg:w-3/4 md:w-full lg:justify-end lg:mr-10 mb-0 ml-3">
        <img
          src={user.profileImage ?? "/profile.webp"}
          alt="user profile"
          className="rounded-full w-32 h-32 object-cover"
        />
      </div>

      {/* Info */}
      <div className="w-full w-3/4 pl-9 lg:justify-start">
        <div className="flex">
          <h1 className="text-2xl font-bold pt-2">{displayName}</h1>

          <details className="dropdown bg-transparent " data-testid="dropdown">
            <summary className="m-2 btn bg-transparent border-0 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 512"
                className="h-4 w-4 mb-5 flex-no-shrink fill-current fill-white"
              >
                <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
              </svg>
            </summary>
            <ul className="w-4 p-2 shadow menu dropdown-content z-[1] rounded-box w-52 bg-zinc-600">
              <li>
                <p onClick={copyURL}>
                  Share Link
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="h-4 w-4 flex-no-shrink fill-current fill-white"
                  >
                    <path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" />
                  </svg>
                </p>
              </li>
            </ul>
          </details>

          {editable && (
            <button
              onClick={onClickEditProfile}
              className="btn normal-case bg-zinc-800 hover:bg-zinc-800 ml-4"
            >
              Edit Profile
            </button>
          )}
        </div>

        <p className="my-2">{user.bio}</p>

        <div className="flex mt-2 mb-4">
          <p
            className="font-bold cursor-pointer"
            onClick={showFollowingModal}
          >{`${user.followingCount} Following`}</p>
        </div>
      </div>
    </div>
  );
};

export default UserBanner;
