import Modal from "../../../../../../components/Modal";
import TagSelector from "../../../../../../components/TagSelector";
import React, { useState } from "react";

type Props = {
  handleSubmit;
  open;
  handleToggle;
  albums;
  isLoading;
  error;
  selectedTags;
  handleTagSelectChange;
};

const UploadTrackModal = ({
  handleSubmit,
  open,
  handleToggle,
  albums,
  isLoading,
  error,
  selectedTags,
  handleTagSelectChange,
}: Props) => {
  return (
    <Modal open={open} onClose={handleToggle}>
      <h3>Upload Track</h3>
      <form className="w-full" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name</label>
          <input
            className="bg-black shadow appearance-none border rounded w-full mt-2 p-3 text-white focus:outline-none focus:shadow-outline"
            placeholder="My Track"
            name="name"
            type="text"
          />
        </div>

        <div className="mt-3 future-update">
          <label htmlFor="explicitTrack">Flag as Explicit:</label>
          <small> Coming soon...</small>
          {/* <input
              name="explicitTrack"
              type="checkbox"
			  className="align-bottom"
            /> */}
        </div>

        <div className="mt-3">
          <label htmlFor="albumId">Add to album:</label>
          <select name="albumId" className="select block bg-black text-white">
            <option defaultValue={"null"}>Choose an album</option>
            {albums.map((album) => (
              <option value={album.id} key={album.id}>
                {album.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-3">
          <label htmlFor="audioFile">Audio File</label>
          <br />
          <small>
            Accepted file types: "mp3, wav, m4a, aac"
            <br />
            Size limit: "200mb"
          </small>
          <input
            className="block w-full text-sm text-slate-500
				file:mr-4 file:py-2 file:px-4
				file:rounded-full file:border-0
				file:text-sm file:font-semibold
				file:bg-yellow-50 file:text-yellow-700
				hover:file:bg-yellow-100 mt-3"
            type="file"
            name="audioFile"
          />
        </div>
        <br />
        <TagSelector
          onSelectChange={handleTagSelectChange}
          tags={selectedTags}
        />

        <div className="modal-action">
          <button
            className="btn normal-case transition duration-300 ease-in-out hover:bg-neutral-700"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
      {error && (
        <div className="alert alert-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error}</span>
        </div>
      )}
    </Modal>
  );
};

export default UploadTrackModal;
