export const Switch = ({
  checked,
  onChange,
  label = <></>,
  size = "md",
  color = "",
  disabled = false,
}) => {
  return (
    <div className="form-control">
      <label className="label cursor-pointer">
        <input
          type="checkbox"
          className={`toggle toggle-${size} ${color && `toggle-${color}`}`}
          defaultChecked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {label ? label : <></>}
      </label>
    </div>
  );
};
