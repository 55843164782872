// Footer.tsx
import React, { useState, useRef, useEffect, useContext } from "react";
import Hls from "hls.js"; // Import HLS.js library
import PlaylistModal from "../PlaylistModal";
import "./MusicPlayerFooter.css";
import { Link } from "react-router-dom";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { ApiContext, UserContext } from "../../context";
import placeholder from "../../assets/images/stagedive-logo.png";
import LikeButton from "../LikeButton";
import { useErrorHandler } from "../../context/ErrorHandler";

export default function MusicPlayerFooter() {
  //Use the provider to manage the states of the playlist so that it's consistent among multiple components
  const {
    playlistData,
    setPlaylistData,
    currentSongIndex,
    setCurrentSongIndex,
    isPlaying,
    setIsPlaying,
    autoplayNext,
    setAutoplayNext,
    isLoading,
    setIsLoading,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    isHLSInitialized,
    setIsHLSInitialized,
    volume,
    setVolume,
    videoRef,
    canStream,
    setIsLiked,
    isLiked,
    setLikeForTrack,
  } = usePlaylistContext();

  const [showPlaylistModal, setShowPlaylistModal] = useState(false);

  const stagedive = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const [isSeeking, setIsSeeking] = useState(false);

  const { handleError } = useErrorHandler();

  //Variable used to track time for analytics
  let timeListened = 0;

  //Function to send track listen event to the API
  const sendTrackListenEvent = async (timeListened: number) => {
    if (playlistData[currentSongIndex] && timeListened >= 10) {
      const eventData = {
        event: "track_listen",
        properties: {
          trackId: playlistData[currentSongIndex].id,
          creatorIds: [playlistData[currentSongIndex].creators[0].id],
          seconds: timeListened,
        },
      };
      try {
        const response = await stagedive.createEvent(eventData);
      } catch (error) {
        handleError(error);
      }
      //Reset after sending, changed from 0 to keep remainder after sending
      //timeListened = 0;
      //console.log("sent request and timelistened is now " + timeListened + " Creator Name " + [playlistData[currentSongIndex].creators[0].name]);
      timeListened -= Math.floor(timeListened);
    }
  };

  //Event listener to the audio tag to track time spent listening
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.ontimeupdate = () => {
        setCurrentTime(videoRef.current!.currentTime);
      };
      let intervalId: NodeJS.Timeout;
      const startInterval = () => {
        intervalId = setInterval(() => {
          // Check if the audio is playing and not seeking
          if (isPlaying && !isSeeking) {
            timeListened += 1;
            if (timeListened >= 10) {
              sendTrackListenEvent(timeListened);
              timeListened = 0;
            }
          }
        }, 1000);
      };

      // Start the interval when the audio starts playing
      if (isPlaying && !isSeeking) {
        startInterval();
      }

      // Stop the interval when the component is unmounted
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPlaying, playlistData, currentSongIndex, isSeeking]);

  // If a track is being started, record a 'play' count
  useEffect(() => {
    //Function to send track play event to the API
    const sendTrackPlayEvent = async () => {
      if (playlistData[currentSongIndex]) {
        const eventData = {
          event: "track_played",
          properties: {
            trackId: playlistData[currentSongIndex].id,
            creatorIds: [playlistData[currentSongIndex].creators[0].id],
          },
        };
        try {
          const response = await stagedive.createEvent(eventData);
        } catch (error) {
          console.error("Error posting event:", error);
        }
      }
    };

    if (videoRef.current) {
      videoRef.current.onplay = () => {
        if (
          typeof videoRef.current?.currentTime === "number" &&
          isFinite(videoRef.current?.currentTime)
        ) {
          if (videoRef.current?.currentTime < 0.01) {
            // console.log('started streaming ', playlistData[currentSongIndex]);
            sendTrackPlayEvent();
          }
        }
        setIsPlaying(true);
      };
    }
  }, [
    autoplayNext,
    playlistData,
    currentSongIndex,
    videoRef,
    setIsPlaying,
    stagedive,
  ]);

  //Checks to see if the hls is initialized then loads current song.
  useEffect(() => {
    if (isHLSInitialized) {
      loadCurrentSong();
    }
  }, [isHLSInitialized, currentSongIndex]);

  useEffect(() => {
    const getNewReleases = async () => {
      try {
        //Check if playlistData is empty, if it is, then set it to New Releases
        if (playlistData.length == 0) {
          const playlists = await stagedive.getLatestTracks(30);
          setPlaylistData(playlists);
        }
        // Marks HLS initialization as done when we get response
        setIsHLSInitialized(true);
      } catch (error) {
        console.error(error);
      }
    };

    getNewReleases();
  }, [setIsHLSInitialized, setPlaylistData, stagedive]);

  // Add padding to bottom of page when footer is loaded
  useEffect(() => {
    $("main").css("padding-bottom", $(".sd-footer").get(0)!.offsetHeight);
  }, []);

  const currentSong = {
    albumName: playlistData[currentSongIndex]?.album?.name,
    albumArt:
      playlistData[currentSongIndex]?.album?.coverImageUrl ?? placeholder, // Album art url
    id: playlistData[currentSongIndex]?.id,
    title: playlistData[currentSongIndex]?.name, // Song Title
    artist:
      playlistData[currentSongIndex]?.creators?.[0]?.name ?? "Unknown Artist", // Artist Name
    artistID: playlistData[currentSongIndex]?.creators?.[0]?.id ?? "Unknown ID", //Artist ID
  };

  // First Load Effect
  useEffect(() => {
    if (videoRef.current) {
      // Initialize Volume to our default value
      videoRef.current.volume = volume;
      //Add the time if it is set in localstorage
      if (currentTime > 0) {
        videoRef.current.currentTime = currentTime;
      }
      // Assign onplay/onpause event listeners
      videoRef.current.onplay = function () {
        if (!canStream) {
          videoRef.current?.pause();
          alert(
            "Please visit your Account page to SUBSCRIBE to StageDive and start streaming!"
          );
        } else {
          setIsPlaying(true);
        }
      };
      videoRef.current.onpause = () => setIsPlaying(false);
      videoRef.current.onloadedmetadata = () =>
        setDuration(videoRef.current!.duration);
    }
  }, []);

  // Set up the 'onended' event handler with the correct playlist length
  useEffect(() => {
    if (playlistData.length > 0) {
      if (videoRef.current) {
        videoRef.current.onended = () => handleSongEnded(playlistData.length);
      }
    }
  }, [playlistData]);

  // Play song
  useEffect(() => {
    if (videoRef.current && autoplayNext && canStream) {
      videoRef.current.muted = true;
      videoRef.current
        .play()
        .then(() => {
          if (videoRef.current) videoRef.current.muted = false;
          
          // On play, set mediaSession track data
          navigator.mediaSession.metadata = new MediaMetadata({
            title: currentSong.title,
            artist: currentSong.artist,
            album: currentSong.albumName,
            artwork: [{src: currentSong.albumArt}]
          });
          
          // Media is loaded, set the duration for mediaSession.
          updatePositionState();
        })
        .catch((error) => {
          console.error("Autoplay failed:", error);
        });
      setAutoplayNext(false);
    }
  }, [autoplayNext]);
  
  // Set timestamp on mediaSession
  function updatePositionState() {
    if ('setPositionState' in navigator.mediaSession && videoRef.current) {
      navigator.mediaSession.setPositionState({
        duration: videoRef.current.duration,
        playbackRate: videoRef.current.playbackRate,
        position: videoRef.current.currentTime
      });
    }
  }

  const loadCurrentSong = () => {
    const currentSongUrl = playlistData[currentSongIndex]?.url;

    if (currentSongUrl && canStream) {
      setIsLoading(true);

      if (Hls.isSupported() && videoRef.current) {
        const hls = new Hls();
        hls.config.xhrSetup = function (xhr, url) {
          // Include the 'Credentials' option to enable sending cookies
          xhr.withCredentials = true;
        };
        hls.loadSource(currentSongUrl);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          setIsLoading(false);
          if (!isPlaying && autoplayNext) {
            setAutoplayNext(true);
          }
        });
      } else if (
        videoRef.current?.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = currentSongUrl;
        setIsLoading(false);
        // Autoplay or start playback here if needed
        if (autoplayNext) {
          videoRef.current.play().catch((error) => {
            console.error("Autoplay failed:", error);
          });
        }
      }
    }
  };

  const handleSongEnded = (playlistLength) => {
    setCurrentTime(0);
    setCurrentSongIndex((songIndex) => (songIndex + 1) % playlistLength);
    setAutoplayNext(true);
    setLikeForTrack(playlistData[currentSongIndex + 1]);
  };

  const handlePlayPause = () => {
    if (canStream) {
      setIsPlaying(!isPlaying);
      if (isPlaying) {
        videoRef.current?.pause();
      } else {
        if (videoRef.current && !autoplayNext) {
          setAutoplayNext(true);
        }
        videoRef.current?.play().catch((error) => {
          console.error("Autoplay failed:", error);
        });
      }
    }
    if (!canStream) {
      alert(
        "To stream, please visit your Account page and Subscribe to StageDive."
      );
    }
  };

  /* Previous Track & Next Track mediaSession controls */
  navigator.mediaSession.setActionHandler('previoustrack', function() {
    handlePreviousSong();
  });

  navigator.mediaSession.setActionHandler('nexttrack', function() {
    handleNextSong();
  });

  const handleNextSong = () => {
    if (canStream) {
      setCurrentTime(0); // Reset currentTime to 0
      setCurrentSongIndex((songIndex) => (songIndex + 1) % playlistData.length);
      setAutoplayNext(true); // Enable autoplay for next song
      setLikeForTrack(playlistData[currentSongIndex + 1]);
    }
  };

  const handlePreviousSong = () => {
    if (canStream) {
      setCurrentTime(0); // Reset currentTime to 0
      setCurrentSongIndex((songIndex) =>
        songIndex === 0 ? playlistData.length - 1 : songIndex - 1
      );
      setAutoplayNext(true);
      setLikeForTrack(playlistData[currentSongIndex - 1]);
    }
  };

  const handleSeek = (e) => {
    if (canStream) {
      setIsSeeking(true);
      const newTime = parseFloat(e.target.value);
      setCurrentTime(newTime);
      if (videoRef.current) {
        videoRef.current.currentTime = newTime;
      }
      setIsSeeking(false);
    }
  };

  //Can potentially cache user's volume, set as default
  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const handleLikeTrack = async (liked) => {
    const trackId = playlistData[currentSongIndex].id;
    try {
      if (liked) {
        await stagedive.likeTrack(trackId);
        setIsLiked(true);
      } else {
        await stagedive.unLikeTrack(trackId);
        setIsLiked(false);
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className="sd-footer music-player-footer flex-col md:flex-row text-primary">
      {/* Left side content */}
      <div className="footer-left md:w-[25%]">
        <div className="album-info">
          <img
            src={currentSong.albumArt}
            alt="Album Art"
            className="object-contain bg-black"
          />
          <div className="song-details">
            <Link to={`/track/${currentSong.id}`}>
              <p className="song-title">{currentSong.title}</p>
            </Link>
            <Link to={`/creator/${currentSong.artistID}`}>
              <p className="song-artist">{currentSong.artist}</p>
            </Link>
          </div>
        </div>
      </div>
      {/* Center content */}
      <div className="footer-center flex flex-col pb-5 w-[70%] md:w-[50%] text-primary">
        <div className="media-controls">
          <span
            onClick={handlePreviousSong}
            className="material-symbols-rounded previous"
          >
            skip_previous
          </span>
          <span
            onClick={handlePlayPause}
            className="material-symbols-rounded play-pause"
          >
            {isPlaying ? "pause" : "play_arrow"}
          </span>
          <span
            onClick={handleNextSong}
            className="material-symbols-rounded next"
          >
            skip_next
          </span>
        </div>
        <div className="progress-bar w-[90%]">
          <div>{formatTime(currentTime)}</div>
          <input
            type="range"
            min="0"
            max={duration}
            step="0.1"
            value={currentTime}
            onChange={handleSeek}
          />
          <div>{formatTime(duration)}</div>
        </div>
      </div>

      {/* Right side content */}
      <div className="footer-right justify-around sm:justify-center md:justify-end w-10 sm:w-1/2 md:w-[25%] text-primary">
        <div className="mb-2 mx-1">
          <LikeButton liked={isLiked} onClick={handleLikeTrack} />
        </div>
        <a
          className="tooltip tooltip-bottom"
          data-tip="Add current track to playlist"
          onClick={() => {
            setShowPlaylistModal(!showPlaylistModal);
          }}
        >
          <span className="material-symbols-rounded">playlist_add</span>
        </a>

        <span className="material-symbols-rounded volume-label hidden sm:block">volume_up</span>
        <input
          className="volume-bar hidden sm:block"
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
        <span className="material-symbols-rounded show-more">more_vert</span>
      </div>

      <audio ref={videoRef} style={{ display: "none" }} controls />
      <PlaylistModal
        open={showPlaylistModal}
        handleToggle={() => {
          setShowPlaylistModal(!showPlaylistModal);
        }}
        track={playlistData[currentSongIndex]}
      ></PlaylistModal>
    </div>
  );
}

// Helper function to format time in minutes:seconds
function formatTime(time: number): string {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

// Event listeners for the 'show more' icon on mobile footer
$(function () {
  $(document).on("click", ".show-more", function (e) {
    if (window.innerWidth < 601) {
      e.stopPropagation();
      $(".footer-right").animate({ top: "0%" });
      $(".show-more").hide();
      $(".footer-right > *:not(.show-more, .volume-bar, .volume-label)").fadeIn();
    }
  });

  $(document).on("click", function (e) {
    if (window.innerWidth < 601) {
      if (!$(e.target).parent(".footer-right").length) {
        e.stopPropagation();
        $(".footer-right").animate({ top: "70%" });
        $(".footer-right > *:not(.show-more)").hide();
        $(".show-more").fadeIn();
      }
    }
  });
});
