import React, { useContext } from "react";
import * as Sentry from "@sentry/browser";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ApiContext, UserContext } from "../../../../context";
import { toast } from "sonner";
import { CrossCircledIcon, CheckCircledIcon } from "@radix-ui/react-icons";
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from "../../../../primitives";

const formSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  socials: z.string(),
  bio: z
    .string()
    .min(10, { message: "Bio must be at least 10 characters long" })
    .optional(),
  email: z.string().email({ message: "Invalid email address" }),
});

type Props = {
  open: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const RequestCreatorAccountForm = ({ onOpenChange, open }: Props) => {
  const stagedive = useContext(ApiContext);
  const { user } = useContext(UserContext);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);

    try {
      if (user.id) {
        const creatorData = {
          details: {
            message: values.socials,
            creatorName: values.name,
            feedback: values.bio,
            contactEmail: values.email,
          },
          status: "pending",
          userId: user.id,
        };
        try {
          const response = await stagedive.createCreatorRequest(creatorData);
          onOpenChange(!open);
          toast.success("Submited", {
            description:
              "Thank you for requesting a creator account. Your request is being reviewed by our team.",
          });
        } catch (error) {
          Sentry.captureException(error, { extra: creatorData });
          console.error("Error requesting creator account:", error);
          toast.warning(
            <div className="flex justify-center items-center gap-2">
              <CrossCircledIcon className="w-4 h-4 text-error" />{" "}
              <p>Creator Request failed, please try again later</p>
            </div>
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="StageDive" {...field} />
              </FormControl>
              <FormDescription>
                This is your public display name.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contact Email</FormLabel>
              <FormControl>
                <Input placeholder="StageDive@gmail.com" {...field} />
              </FormControl>
              <FormDescription>Artist Email</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="socials"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Socials</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Please link your artist socials here (Instagram, YouTube, X,
                  etc.)"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="bio"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bio</FormLabel>
              <FormControl>
                <Textarea placeholder="Tell us about yourself" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          onClick={() => {
           
          }}
        >
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default RequestCreatorAccountForm;
