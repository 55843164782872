import { useEffect, useState, useContext } from "react";
import { ApiContext } from "../../context";
import { useParams } from "react-router-dom";
import { usePlaylistContext } from "../../context/PlaylistContext";
import Album from "../../components/Album";

function AlbumView() {
  const { id } = useParams();

  const { playNewTrack, clearAndLoadNewPlaylist } = usePlaylistContext();

  const stagedive = useContext(ApiContext);

  const [Albums, setAlbums] = useState<Album>();
  const [creator, setCreator] = useState<string>("");
  const [albumName, setAlbumName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //Gets the data for the passed in Album ID
    const getAlbumData = async (albumId) => {
      setIsLoading(true);
      try {
        const album = await stagedive.getAlbum(albumId);
        setAlbums(album);
        const creator = album.creators[0].name;
        const albumName = album.name;
        setCreator(creator);
        setAlbumName(albumName);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    getAlbumData(id);
  }, [id]);

  if (isLoading) {
    return (
      <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
    );
  } else
    return (
      <div className="fill">
        <h1 className="scroll-m-20 text-primary pb-2 text-3xl font-semibold tracking-tight first:mt-0">
          {albumName}
        </h1>
        <h2 className="scroll-m-20 text-primary pb-2 text-1xl font-semibold tracking-tight first:mt-0">
          {creator}
        </h2>
        {/* need to include loading state -> isLoading */}
        <Album
          album={Albums}
          onAlbumEdit={() => {}}
          onTrackEdit={() => {}}
          onTrackDelete={() => {}}
          editable={false}
        />
      </div>
    );
}

export default AlbumView;
