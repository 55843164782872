import { ApiContext, UserContext } from "../../context";
import {
  ListViewCarousel,
  SmallCardCarousel,
} from "../../components/MusicSections";
import { useState, useEffect, useContext } from "react";
import { LibraryPlaylistCarousel } from "../../components/LibraryPlaylistCarousel";

export default function Library() {
  const stagedive = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const [playlists, setPlaylists] = useState([] as any);
  const [likedTracks, setLikedTracks] = useState([] as any);
  const [followingLatestTracks, setFollowingLatestTracks] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPlaylists = async () => {
      try {
        const playlists = await stagedive.getUserPlaylists(user.id);
        setPlaylists(playlists);
      } catch (error) {
        console.error(error);
      }
    };

    const getLikedTracks = async () => {
      try {
        const likedTracks = await stagedive.getUserLikedTracks(user.id);
        setLikedTracks(likedTracks);
      } catch (error) {
        console.error(error);
      }
    };

    const getFollowingLatestTracks = async () => {
      try {
        const followingLatestTracks = await stagedive.getFollowingLatestTracks(
          user.id
        );
        setFollowingLatestTracks(followingLatestTracks);
      } catch (error) {
        console.error(error);
      }
    };

    if (user.id) {
      getPlaylists();
      getLikedTracks();
      getFollowingLatestTracks();
      setIsLoading(false);
    }
  }, [user, stagedive]);

  return (
    <div className="p-10" id="profile-container">
      <h1 className="text-center">My Library</h1>
      <section>
        {isLoading ? (
          <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
        ) : null}

        <ListViewCarousel
          title="Followed Creators"
          tracks={followingLatestTracks}
        />

        <ListViewCarousel title="Liked Tracks" tracks={likedTracks} />

        {playlists !== undefined && playlists.length > 0
          ? playlists.map((playlist) => (
              <div key={playlist.id}>
                <LibraryPlaylistCarousel
                  playlistData={playlist}
                  editable={true}
                />
              </div>
            ))
          : null}
      </section>
    </div>
  );
}
