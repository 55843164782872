import { useContext, useEffect } from "react";
import { Route, Routes, Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../../context";
import { CreatorRequests } from "./components/CreatorRequests";

const DashboardLayout = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.id && !user.roles.includes("admin")) navigate("/"); // Redirect if not admin
  }, [user, navigate]);

  return (
    <div className="p-6">
      <h1>Admin Dashboard</h1>
      <Outlet />
    </div>
  );
};

export const AdminDashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route path="creator-requests" element={<CreatorRequests />} />
      </Route>
    </Routes>
  );
};
