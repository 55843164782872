import React, { useContext, useState } from "react";
import MusicCard from "../../components/MusicCard/MusicCard";
import { usePlaylistContext } from "../../context/PlaylistContext"; //TO MOVE
import "../../components/MusicSections/SmallCardCarousel.css";
import { PlayIcon } from "@radix-ui/react-icons";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/Carousel";
import { Switch } from "../../primitives/Switch";
import { ApiContext } from "../../context";
import { useErrorHandler } from "../../context/ErrorHandler";

interface LibraryPlaylistCarousel {
  playlistData: any;
  editable: boolean;
}

export const LibraryPlaylistCarousel: React.FC<LibraryPlaylistCarousel> = ({
  playlistData,
  editable,
}) => {
  const [playlist, setPlaylist] = useState(playlistData);
  const { clearAndLoadNewPlaylist } = usePlaylistContext();
  const stagedive = useContext(ApiContext);
  const { handleError } = useErrorHandler();

  const handleTrackPrivacyChange = async () => {
    try {
      const playlistDTO = {
        name: playlist.name,
        public: !playlist.public,
        trackIds: playlist.tracks.map((track) => track.id),
      };

      const updatedPlaylist = await stagedive.updatePlaylist(
        playlist.id,
        playlistDTO
      );
      setPlaylist(updatedPlaylist);
    } catch (error) {
      handleError(error);
    }
  };

  const copyURL = () => {
    try {
      const copyText = `${window.location.host}/playlist/${playlist.id}`;
      navigator.clipboard.writeText(copyText);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="music-section">
      <h2 className="scroll-m-20 text-primary pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        {playlist.name}

        {playlist.tracks.length > 0 ? (
          <PlayIcon
            onClick={() => {
              clearAndLoadNewPlaylist(playlist.tracks, 0);
            }}
            className="w-8 h-8 inline border rounded-full p-1 ml-6 text-[#ffe561]/75  cursor-pointer hover:text-[#ffe561] hover:border-[#ffe561] hover:bg-secondary/75 fill-white"
          />
        ) : null}

        <details className="dropdown bg-transparent" data-testid="dropdown">
          <summary className="btn bg-transparent border-0 rounded-full pt-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 512"
              className="h-4 w-4 mb-5 flex-no-shrink fill-current fill-white"
            >
              <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
            </svg>
          </summary>
          <ul className="w-4 p-2 shadow menu dropdown-content z-[1] rounded-box w-52 bg-zinc-600">
            <li>
              <p onClick={copyURL}>
                Share Link
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="h-4 w-4 flex-no-shrink fill-current fill-white"
                >
                  <path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" />
                </svg>
              </p>
            </li>
          </ul>
        </details>

        <p className="ml-auto mb-3 text-stone-400 text-lg">{`${playlist.tracks.length} track(s)`}</p>
      </h2>
      <Carousel
        opts={{
          align: "start",
        }}
        className="w-full"
      >
        <div className="w-full flex items-center justify-between gap-4">
          {editable && (
            <Switch
              checked={playlist.public}
              onChange={handleTrackPrivacyChange}
              label={
                <span
                  className={`label-text ${playlist.public ? "text-white" : "text-stone-400"} ml-2`}
                >
                  {playlist.public ? "Public" : "Private"}
                </span>
              }
            />
          )}

          <div>
            <CarouselPrevious />

            <CarouselNext />
          </div>
        </div>
        {playlist.tracks.length > 0 ? (
          <CarouselContent>
            {playlist.tracks.map((track, index) => (
              <CarouselItem
                key={index}
                className="basis-1/2 sm:basis-1/3 md:basis-1/4 lg:basis-36"
              >
                <MusicCard
                  index={index}
                  trackArray={playlist.tracks}
                  key={track.id}
                  track={track}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        ) : null}
      </Carousel>
    </div>
  );
};
