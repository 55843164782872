import { useSessionContext } from "supertokens-auth-react/recipe/session";
import {
  SmallCardCarousel,
  ListViewCarousel,
} from "../../components/MusicSections";

import React, { useState, useRef, useEffect, useContext } from "react";
import { ApiContext } from "../../context";
import "./Home.css";

export default function Home() {
  const sessionContext = useSessionContext();
  const [newReleaseData, setNewReleaseData] = useState<Track[]>([]);
  const [latestPodData, setlatestPodData] = useState<Track[]>([]);
  const [rapTrackData, setRapTrackData] = useState<Track[]>([]);
  const [rockTrackData, setRockTrackData] = useState<Track[]>([]);
  const [folkTrackData, setFolkTrackData] = useState<Track[]>([]);
  const [electronicaTrackData, setElectronicaTrackData] = useState<Track[]>([]);
  const [countryTrackData, setCountryTrackData] = useState<Track[]>([]);
  const [experimentalTrackData, setExperimentalTrackData] = useState<Track[]>(
    []
  );
  const [punkTrackData, setPunkTrackData] = useState<Track[]>([]);
  const [indieTrackData, setIndieTrackData] = useState<Track[]>([]);
  const [exploreData, setExploreData] = useState<Track[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const stagedive = useContext(ApiContext);

  //Used to parse the list carousel
  const extractDistinctTracksFromAlbums = (tracks: Track[]): Track[] => {
    const albumTrackMap: { [key: number]: Track } = {};

    tracks.forEach((track) => {
      const albumId = track.album.id;
      if (!albumTrackMap[albumId]) {
        albumTrackMap[albumId] = track;
      }
    });

    return Object.values(albumTrackMap);
  };

  //Use the api class to get the top 30 released tracks
  // Removes all of Free Style Lee, A.chan, and Wood[y] tracks

  useEffect(() => {
    const getNewReleases = async () => {
      try {
        const playlists = await stagedive.getLatestTracks(100);
        const trackUnique = extractDistinctTracksFromAlbums(playlists);
        setNewReleaseData(trackUnique);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    //Gets the top 15 latest pods, removes any tracks from the andrews
    const getLatestPods = async () => {
      try {
        const playlists = await stagedive.getLatestPodcasts();
        setlatestPodData(playlists);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const getTracksByTags = async (tagName) => {
      try {
        const playlists = await stagedive.getTracksByTags(
          tagName,
          "20",
          "random",
          "0"
        );
        if (tagName === "Rap") setRapTrackData(playlists);
        else if (tagName === "Rock") setRockTrackData(playlists);
        else if (tagName === "Electronica") setElectronicaTrackData(playlists);
        else if (tagName === "Experimental")
          setExperimentalTrackData(playlists);
        else if (tagName === "Country") setCountryTrackData(playlists);
        else if (tagName === "Folk") setFolkTrackData(playlists);
        else if (tagName === "Punk") setPunkTrackData(playlists);
        else {
          setIndieTrackData(playlists);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const fetchPlaylists = async () => {
      try {
        const responseExplore = await stagedive.getRandomTracks();
        setExploreData(responseExplore);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    getNewReleases();
    fetchPlaylists();
    getLatestPods();
    getTracksByTags("Rap");
    getTracksByTags("Rock");
    getTracksByTags("Electronica");
    getTracksByTags("Experimental");
    getTracksByTags("Country");
    getTracksByTags("Folk");
    getTracksByTags("Punk");
    getTracksByTags("Indie");
  }, [stagedive]);

  if (sessionContext.loading === true) {
    return null;
  }

  return (
    <div className="fill" id="home-container  bg-background">
      <div className="home-page ">
        <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mt-4 text-primary">
          Welcome to StageDive
        </h1>

        <>
          <ListViewCarousel title="New Releases" tracks={newReleaseData} />
          <SmallCardCarousel title="Explore" tracks={exploreData} />
          <SmallCardCarousel title="Latest Podcasts" tracks={latestPodData} />
          <SmallCardCarousel title="Rap" tracks={rapTrackData} />
          <SmallCardCarousel title="Rock" tracks={rockTrackData} />
          <SmallCardCarousel title="Electronic" tracks={electronicaTrackData} />
          <SmallCardCarousel
            title="Experimental"
            tracks={experimentalTrackData}
          />
          <SmallCardCarousel title="Country" tracks={countryTrackData} />
          <SmallCardCarousel title="Folk" tracks={folkTrackData} />
          <SmallCardCarousel title="Punk" tracks={punkTrackData} />
          <SmallCardCarousel title="Indie" tracks={indieTrackData} />
        </>
      </div>
    </div>
  );
}
