import { ApiContext, UserContext } from "../../context";
import { useState, useEffect, useContext } from "react";
import ReactPixel from "react-facebook-pixel";
import placeholder from "../../assets/images/stagedive-logo.png";
import "./index.css";

export default function OrderConfirmation() {
  const stagedive = useContext(ApiContext);
  const { user } = useContext(UserContext);

  //Check if the user is subscribed (initialized in localStorage)
  const isSubscribed = localStorage.getItem("isSubscribed") === "true";

  //Meta Pixel for subscribed
  useEffect(() => {
    if (!isSubscribed && process.env.REACT_APP_ENV === "prod") {
      ReactPixel.init("7941732932538308");
      ReactPixel.track("PageView");
      ReactPixel.track("Subscribe", { value: "3.99", currency: "USD" });
      //Set isSubscribed to true in localStorage
      localStorage.setItem("isSubscribed", "true");
    }
  }, [isSubscribed]);

  return (
    <div className="success-checkout" id="success-checkout">
      <h1>Welcome to the StageDive Community!</h1>
      <p>Your subscription has been successfully activated.</p>
      <p>Enjoy unlimited ad free streaming.</p>
      <button onClick={() => (window.location.href = "/home")}>
        Go to Home
      </button>
    </div>
  );
}
