import { usePlaylistContext } from "../../context/PlaylistContext";
import { PlayIcon } from "@radix-ui/react-icons";

const TrackList = ({ tracks }) => {
  const { playNewTrack, clearAndLoadNewPlaylist } = usePlaylistContext();

  return (
    <div className="bg-neutral-900 rounded-md mb-6 p-6 overflow-x-auto">
      <div className="flex">
        {tracks.length > 0 ? (
          <PlayIcon
            onClick={() => {
              clearAndLoadNewPlaylist(tracks,0);
            }}
            className="w-8 h-8 inline border rounded-full text-[#ffe561]/75  cursor-pointer hover:text-[#ffe561] hover:border-[#ffe561] hover:bg-secondary/75 fill-white"
          />
        ) : null}
      </div>
      <div className="mt-4">
        <div className="flex font-bold mb-3 pl-3">
          <div className="w-12">#</div>
          <div className="flex-1">Track</div>
          <div className="w-12"></div>
        </div>
        {tracks.length > 0 ? (
          tracks.map((track, index) => (
            <div
              className="flex items-center hover:bg-neutral-800 mb-3 pl-3 h-12 rounded-sm"
              key={track.id}
            >
              <div className="w-12">{index + 1}</div>
              <div className="flex-1">{track.name}</div>
              <div className="w-12">
                <span
                  className="material-symbols-rounded play-playlist cursor-pointer"
                  onClick={() => playNewTrack(track)}
                >
                  play_circle
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">No tracks added yet</div>
        )}
      </div>
    </div>
  );
};

export default TrackList;
