import React, { useEffect, useState, useContext } from "react";
import Modal from "../Modal";
import { ApiContext, UserContext } from "../../context";
import { SignInAndUp } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";

type Props = {
  open;
  handleToggle;
  isLoading;
  error;
};

const AuthModal = ({ open, handleToggle, isLoading, error }: Props) => {
  const { user } = useContext(UserContext);

  return (
    <>
      {/* Only load this modal if user is not logged in */}
      {!user.id && (
        <Modal open={open} onClose={handleToggle}>
          <div className="container mx-auto px-0 overflow-x-hidden">
            <SignInAndUp redirectOnSessionExists={false} />
          </div>

          {error && (
            <div className="alert alert-error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>{error}</span>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default AuthModal;
