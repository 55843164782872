import React, { useState } from "react";
import {
  Button,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../../primitives";
import { useWindowSize } from "usehooks-ts";

import RequestCreatorAccountForm from "./RequestCreatorAccountForm";
import { cn } from "../../../../utils";

const RequestAccountDialog = () => {
  const [open, onOpenChange] = useState(false)
  const { width } = useWindowSize();
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetTrigger className="w-min">
        <Button variant={"ghost"}>Request Creator Account</Button>
      </SheetTrigger>
      <SheetContent side={width > 800 ? "right" : "bottom"}
      className={cn(width > 800 ? "" : "h-[98%]")}
      >
        <SheetHeader>
          <SheetTitle>Request Creator Account</SheetTitle>
          <SheetDescription>
            <RequestCreatorAccountForm open={open} onOpenChange={onOpenChange}/>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default RequestAccountDialog;
