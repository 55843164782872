import { useContext } from "react";
import { ApiContext } from "../../../../context";
import { useErrorHandler } from "context/ErrorHandler";
import ReactPixel from "react-facebook-pixel";
import { Link } from "react-router-dom";

type Props = {
  priceId: string;
};

const Checkmark = () => {
  return (
    <svg
      className="flex-shrink-0 w-5 h-5 text-green-500"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};

const PriceCard = ({ priceId }: Props) => {
  const stagedive = useContext(ApiContext);
  const { handleError } = useErrorHandler();

  const handleCheckout = (priceId: string) => {
    // Meta Pixel for checking out
    if (process.env.REACT_APP_ENV === "prod") {
      ReactPixel.init("7941732932538308");
      ReactPixel.track("PageView");
      ReactPixel.track("InitiateCheckout");
    }
    try {
      stagedive.createCheckoutSession(priceId).then((link) => {
        window.location.href = link.url;
      });
    } catch (error) {
      handleError(error);
    }
  };

  const bulletPoints = [
    "Unlimited access to our full music library",
    "New releases added regularly",
    "Ad-free listening experience",
  ];

  return (
    <div className="space-y-8">
      <div className="flex flex-col p-8 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8 bg-zinc-900">
        <h2 className="text-4xl font-extrabold text-white">Start Listening</h2>
        <div className="flex justify-center items-baseline my-8">
          <span className="mr-2 text-3xl font-bold text-white">$3.99</span>
          <span className="text-gray-500">/month</span>
        </div>
        <p className="text-white mb-5">
          Your subscription directly supports creators and also grants:
        </p>

        <ul className="mb-8 space-y-4">
          {bulletPoints.map((point, index) => (
            <li className="flex items-center space-x-3">
              <Checkmark />
              <span className="text-white">{point}</span>
            </li>
          ))}
        </ul>

        <div className="w-full grid place-items-center mb-5">
          <button
            className="btn btn-md normal-case mr-3 hover:bg-neutral-700 text-white border-slate-500"
            onClick={() => handleCheckout(priceId)}
          >
            Buy now
          </button>
        </div>
        <Link className="text-sm text-slate-400 hover:underline mr-2" to="/">
          Maybe Later
        </Link>
      </div>
    </div>
  );
};

export default PriceCard;
