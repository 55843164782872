import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PlaylistProvider } from "./context/PlaylistContext";
import { newRelicOptions } from "./config";

if (process.env.NODE_ENV === 'production') new BrowserAgent(newRelicOptions);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PlaylistProvider>
      <App />
    </PlaylistProvider>
  </React.StrictMode>
);
