import { useEffect, useContext, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";

import { ApiContext, UserContext } from "context";

import { useErrorHandler } from "context/ErrorHandler";
import CheckoutForm from "components/CheckoutForm";
import { useNavigate } from "react-router-dom";

const SignupCheckout = () => {
  const navigate = useNavigate();
  const stagedive = useContext(ApiContext);
  const { user } = useContext(UserContext);

  const { handleError } = useErrorHandler();
  const [checkoutSession, setCheckoutSession] = useState<PaymentURLRedirect>({
    url: "",
    clientSecret: "",
  });
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

  const getStripeSubscriptionProduct = async (): Promise<any> => {
    const products = await stagedive.listStripeProducts();
    return products.data.find((product) => product.name === "Subscription");
  };

  const createCheckoutSessionOrRedirect = async () => {
    try {
      if (user.id) {
        const subscriptions = await stagedive.getUserSubscriptions(user.id);

        // user is already subscribed, redirect to home
        if (subscriptions.length > 0) {
          navigate("/home");
        } else {
          const subscriptionProduct = await getStripeSubscriptionProduct();
          const checkoutSession = await stagedive.createCheckoutSession(
            subscriptionProduct.default_price,
            "embedded"
          );
          setCheckoutSession(checkoutSession);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    createCheckoutSessionOrRedirect();
  }, [user, stagedive]);

  return (
    <div>
      <CheckoutForm
        stripe={stripe}
        clientSecret={checkoutSession.clientSecret}
      />
    </div>
  );
};

export default SignupCheckout;
