import { useState, useEffect, useContext } from "react";
import { ApiContext, UserContext } from "../../../../context";
import PriceCard from "../PriceCard";

const ProfileSubscription = () => {
  const { user } = useContext(UserContext);
  const stagedive = useContext(ApiContext);
  const [subscriptions, setSubscriptions] = useState([] as any);
  const [products, setProducts] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenCustomerPortal = () => {
    try {
      if ((user as any).stripeCustomerId !== "") {
        stagedive
          .createCustomerPortalSession((user as any).stripeCustomerId)
          .then((link) => {
            window.location.href = link.url;
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getSubscriptions = async () => {
      setIsLoading(true);

      try {
        if (user.id) {
          const subscriptions = await stagedive.getUserSubscriptions(user.id);
          setSubscriptions(subscriptions);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getProducts = async () => {
      try {
        const products = await stagedive.listStripeProducts();
        setProducts(products.data);
      } catch (error) {
        console.error(error);
      }
    };

    getSubscriptions();
    getProducts();
  }, [user, stagedive]);

  return (
    <section className="p-10">
      <h1>Subscriptions</h1>

      {isLoading ? <span className="loading loading-spinner"></span> : <></>}
      {!isLoading && subscriptions.length === 0 && (
        <section>
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            {products.map((product) => (
              <PriceCard priceId={product.default_price} />
            ))}
          </div>
        </section>
      )}

      {subscriptions.length > 0 && (
        <section>
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
                You're subscribed!
              </h2>
              <p className="mb-5 font-light text-gray-500 sm:text-xl">
                Manage your subscription below
              </p>
              <button
                className="btn btn-s normal-case mr-3 hover:bg-neutral-700 text-white"
                onClick={() => handleOpenCustomerPortal()}
              >
                Open Customer Portal
              </button>
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

export default ProfileSubscription;
