// @ts-nocheck
// Typescript parsing ignored due to meta pixel code conflict
import { useEffect, useContext } from "react";
import { UserContext } from "../../context";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { Tweet } from "react-tweet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SocialProof from "../../components/SocialProof";
import musicForPeopleImage from "../../assets/images/musicForPeople.jpg";
import piano from "../../assets/images/piano.jpg";
import "./Juiced.css";
import ReactPixel from "react-facebook-pixel";

export default function Juiced() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  // Hero slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Redirect to home page if already logged in to an account
  useEffect(() => {
    if (user.id) navigate("/home");
  }, [user, navigate]);

  // Meta Pixel for Landing Page
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      ReactPixel.init("7941732932538308");
      ReactPixel.track("PageView");
    }
  }, []);

  return (
    <div id="landing-page">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-purple-700 to-blue-900 text-white py-20 px-4">
        <div className="contai%2Fsignup%2Fcheckoutner mx-auto max-w-4xl text-center">
          <h1 className="text-4xl font-bold mb-4">
            Get 25% off your first month at StageDive
          </h1>
          <p className="text-lg mb-8">A fair-pay music platform.</p>
          <Link
            to={`/auth/?show=signup`}
            className="bg-base-200 text-white font-semibold py-2 px-6 rounded-full hover:bg-base-100 transition duration-300"
          >
            Start Listening Today
          </Link>
        </div>
      </section>

      {/* Impact Section */}
      {/* <section className="py-16 px-4">
			<div className="container mx-auto">
				<div className="max-w-4xl text-center mx-auto mb-4">
					<h2 className="text-3xl font-bold">Make an Impact</h2>
				</div>
				<ul className="list-disc text-white mx-auto w-fit">
					<li>50% of all subscriptions go directly to our artists.</li>
					<li>Payout is targeted to the artists <em>you</em> listen to.</li>
					<li>Discover new and rising creators, on the <br/>platform that benefits them most.</li>
				</ul>
			</div>
		</section> */}

      {/* Features Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl text-center mx-auto">
          <h2 className="text-3xl font-bold mb-8">At StageDive, you get:</h2>
        </div>
        <div className="container mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-base-200 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">A fair-pay platform</h3>
            <p className="text-white">
              50% of paid subscriptions go directly to the artists you love.
            </p>
          </div>
          <div className="bg-base-200 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">Ad-free streaming</h3>
            <p className="text-white">You're here for music, not ads.</p>
          </div>
          <div className="bg-base-200 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">
              A growing catalog of independent artists
            </h3>
            <p className="text-white">
              Discover music and support new artists that need it most.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="text-white pt-12 pb-24 px-4">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">
            Make an Impact. <br />
            Sign up now.
          </h2>
          <p className="text-lg mb-8">1 plan. 1 price. Simple.</p>
          <div className="card bg-base-200 w-96 mx-auto max-w-full">
            <div className="card-body">
              <h2 className="card-title text-blue-500">
                StageDive Subscription
              </h2>
              <p className="text-left">Only $3.99/month &#40;USD&#41;</p>
              <ul className="text-left">
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  Directly supports the artists you listen to
                </li>
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  Ad-free streaming
                </li>
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  High quality audio
                </li>
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  A growing library of independent artists
                </li>
              </ul>
              <div className="card-actions mx-auto">
                <Link
                  to={`/auth/?show=signup`}
                  className="btn btn-primary bg-blue-600 border-none"
                >
                  Get StageDive
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-gray-900 text-white py-12 px-4">
        <div className="container mx-auto text-center">
          <p className="text-lg">
            With love for all artists &#40;even your mom&#41;
            <span className="material-symbols-rounded py-0 align-bottom">
              favorite
            </span>
          </p>
        </div>
      </section>

      {/* Q&A Section */}
      <section className="text-white py-24 px-4">
        <div className="container mx-auto max-w-5xl">
          <h2 className="text-3xl font-bold mb-3 text-center">
            Frequently Asked Questions
          </h2>
          <p className="text-sm text-center pb-6">
            Don't see your question here? Find our general <br />
            FAQ and Support links at the bottom of the page.
          </p>
          <div className="collapse collapse-arrow rounded-none border-b-2 border-slate-500">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              How are artists paid?
            </div>
            <div className="collapse-content">
              <p>
                Every month, you pay $3.99 to StageDive.
                <br />
                <br />
                $1.99 goes to StageDive's operations and development.
                <br />
                <br />
                The remaining $2.00 is split between the artists that you
                listened to that month, relative to the time spent with each
                artist.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow rounded-none border-b-2 border-slate-500">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Can I be a creator on StageDive?
            </div>
            <div className="collapse-content">
              <p>
                Anyone can request to be a creator on StageDive.{" "}
                <Link
                  to={`/auth/?show=signup&redirectToPath=%2Fprofile%2Fcreator-dashboard`}
                  className="underline"
                >
                  Apply for an account here
                </Link>{" "}
                to start your journey.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow rounded-none border-b-2 border-slate-500">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Is there a StageDive app for iOS or Android?
            </div>
            <div className="collapse-content">
              <p>
                While we are working diligently to make StageDive a
                fully-featured and rich streaming experience, we currently do
                not have a mobile app available for download. Stagedive can be
                streamed from any browser. You may use{" "}
                <Link to="/add-to-home" className="underline">
                  this reference
                </Link>{" "}
                to set up a shortcut on your mobile device.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
