import { useState, useEffect, useContext } from "react";
import { ApiContext, UserContext } from "../../context";
import PriceCard from "../Profile/components/PriceCard";

const PostRegisterBuyNow = () => {
  const { user } = useContext(UserContext);
  const stagedive = useContext(ApiContext);
  const [products, setProducts] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);

      try {
        const products = await stagedive.listStripeProducts();
        setProducts(products.data);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    getProducts();
  }, [user, stagedive]);

  return (
    <section className="p-6">
      {isLoading ? <span className="loading loading-spinner"></span> : <></>}
      {!isLoading && (
        <section>
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            {products.map((product) => (
              <PriceCard priceId={product.default_price} />
            ))}
          </div>
        </section>
      )}
    </section>
  );
};

export default PostRegisterBuyNow;
