import { useContext, useEffect, useState } from "react";

import AlbumList from "../../../../components/AlbumList";
import CreateAlbumModal from "./components/CreateAlbumModal";

import { ApiContext, UserContext } from "../../../../context";
import "./index.css";
import UploadTrackModal from "./components/UploadTrackModal";
import { getWebsiteDomain } from "../../../../config";

import Analytics from "./components/Analytics";
import MonetizationModal from "./components/MonetizationModal";
import CreatorEditProfileModal from "./components/CreatorEditProfileModal";
import CreatorBanner from "../../../../components/CreatorBanner";
import { useErrorHandler } from "context/ErrorHandler";

const CreatorDashboard = () => {
  const stagedive = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const { handleError } = useErrorHandler();

  const [creator, setCreator] = useState({ socials: {} } as any);
  const [albums, setAlbums] = useState([] as Album[]);
  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const [openTrackModal, setOpenTrackModal] = useState(false);
  const [openMonetizationModal, setOpenMonetizationModal] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);

  const [disableModal, setDisableModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [albumError, setAlbumError] = useState(null as any);
  const [trackError, setTrackError] = useState(null as any);
  const [editProfileError, setEditProfileError] = useState(null as any);

  type Tag = {
    value: string;
    label: string;
  };
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const handleTagSelectChange = (selectedTags) => {
    setSelectedTags(selectedTags);
  };

  const redirectToStripeAccountLink = (refresh: boolean) => {
    setIsLoading(true);
    try {
      if (refresh) {
        stagedive.createRefreshStripeAccountLink().then((link) => {
          window.location.href = link.url;
        });
      } else {
        setOpenMonetizationModal(true);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const copyURL = () => {
    // Get the text field
    const copyText = `${getWebsiteDomain()}/creator/${creator.id}`;

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText);

    // Notify that it was copied
    $("#copy-url").attr("data-tip", "Copied!");
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true);
      try {
        if (user.id && user.id !== "") {
          const creators = await stagedive.getUserCreators(user.id);
          if (creators.length > 0) {
            const creator = creators[0];
            const albums = await stagedive.getCreatorAlbums(creator.id);
            setCreator(creator);
            setAlbums(albums);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchDashboardData();
  }, [user, stagedive]);

  const toggleCreateAlbumModal = () => {
    setOpenAlbumModal((open) => !open);
  };

  const toggleUploadTrackModal = () => {
    setOpenTrackModal((open) => !open);
  };

  const toggleMonetizationModal = () => {
    setOpenMonetizationModal((open) => !open);
  };

  const toggleEditProfileModal = () => {
    setOpenEditProfileModal((open) => !open);
  };

  const createAlbum = async (event) => {
    event.preventDefault();

    try {
      const album = new FormData(event.target);
      album.append("creatorIds", creator.id);

      if (album.get("name") === "") {
        // TODO should use validation lib here or pass validation errors from backend
        alert("Album name cannot be empty");
        return false;
      }

      setDisableModal(true);
      const newAlbum = await stagedive.createAlbum(album);
      setAlbums([...albums, newAlbum] as any);
      setAlbumError(null);
    } catch (error: any) {
      handleError(error);
      setAlbumError(`Unable to create album: ${error.toString()}`);
    }

    setDisableModal(false);
    toggleCreateAlbumModal();
  };

  const updateCreator = async (event) => {
    event.preventDefault();

    try {
      const creatorDTO = new FormData(event.target);

      const profileImageFile = creatorDTO.get("profileImageFile") as File;
      if (profileImageFile && profileImageFile.size === 0) {
        creatorDTO.delete("profileImageFile");
      }

      setDisableModal(true);
      const updatedCreator = await stagedive.updateCreator(
        creator.id,
        creatorDTO
      );
      setCreator(updatedCreator);
      setEditProfileError(null);
    } catch (error: any) {
      handleError(error);
      setEditProfileError(`Unable to update creator: ${error.statusText}`);
    }

    setDisableModal(false);
    toggleEditProfileModal();
  };

  const uploadTrack = async (event) => {
    event.preventDefault();

    const track = new FormData(event.target);
    track.append("creatorIds", creator.id);
    console.log(track);

    try {
      const tagIds = selectedTags.map((tag) => tag.value);
      tagIds.forEach((tagId) => {
        track.append("tagIds", tagId);
      });

      // TODO should use validation lib here or pass validation errors from backend
      if (track.get("name") === "") {
        alert("Track name cannot be empty");
        return false;
      }

      if (track.get("albumId") === "Choose an album") {
        alert("Please select an album");
        return false;
      }

      const allowedExtensions = [".mp3", ".wav", ".m4a", ".aac"];
      const fileName = (track.get("audioFile") as any).name;
      const fileExtension = fileName.substring(fileName.lastIndexOf("."));
      if (!allowedExtensions.includes(fileExtension)) {
        alert(`Please upload ${allowedExtensions.join(" ")} file types`);
        return false;
      }

      setDisableModal(true);
      const newTrack = await stagedive.uploadTrack(track);

      const updatedAlbums = albums.map((album: any) => {
        if (album.id === track.get("albumId")) {
          return { ...album, tracks: [...album.tracks, newTrack] };
        } else {
          return album;
        }
      });

      setAlbums(updatedAlbums as any);
      setTrackError(null);
      toggleUploadTrackModal();
    } catch (error: any) {
      handleError(error);
      setTrackError(`Unable to upload track: ${error.toString()}`);
    }

    setDisableModal(false);
  };

  return (
    <div className="p-10">
      <CreatorBanner
        creator={creator}
        showFollowingButton={false}
        isFollowed={false}
        followCreator={() => {}}
      />

      {/* Stripe Notice */}
      {!user.stripeAccountId && (
        <div
          className="flex items-center text-white text-sm font-bold px-4 py-3 my-3 background-blue rounded-lg"
          role="alert"
        >
          <span className="material-symbols-rounded play-pause">warning</span>
          <p>
            You have not yet signed up for Creator Monetization.{" "}
            <button
              className="inline-block border-2 font-bold p-2 rounded-lg"
              onClick={() => redirectToStripeAccountLink(false)}
              disabled={isLoading}
            >
              Click Here
            </button>{" "}
            to start earning money on StageDive.
          </p>
        </div>
      )}

      {/* Copy Profile Link */}
      <section className="flex justify-between">
        <button
          onClick={copyURL}
          onMouseOut={() => {
            $("#copy-url").attr("data-tip", "Copy to Clipboard");
          }}
          className="flex items-center tooltip"
          data-tip="Copy to Clipboard"
          id="copy-url"
        >
          <span className="material-symbols-rounded text-white">
            content_copy
          </span>
          Share My Profile Link
        </button>
      </section>

      <Analytics></Analytics>

      {isLoading ? (
        <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
      ) : (
        <section>
          <div className="flex flex-wrap gap-5 justify-between items-center p-3">
            <h2>Manage tracks and albums for {creator.name}</h2>
            <div className="flex flex-wrap gap-2 md:gap-3">
              <button
                className="btn normal-case"
                onClick={toggleCreateAlbumModal}
              >
                Add album
              </button>
              <button
                className="btn normal-case"
                onClick={toggleUploadTrackModal}
              >
                Upload track
              </button>
              <button
                onClick={toggleEditProfileModal}
                className="btn normal-case"
              >
                Edit Profile
              </button>
            </div>
          </div>

          <UploadTrackModal
            open={openTrackModal}
            handleSubmit={uploadTrack}
            handleToggle={toggleUploadTrackModal}
            albums={albums}
            isLoading={disableModal}
            error={trackError}
            selectedTags={selectedTags}
            handleTagSelectChange={handleTagSelectChange}
          />
          <CreateAlbumModal
            open={openAlbumModal}
            handleSubmit={createAlbum}
            handleToggle={toggleCreateAlbumModal}
            isLoading={disableModal}
            error={albumError}
          />
          <CreatorEditProfileModal
            open={openEditProfileModal}
            creator={creator}
            handleSubmit={updateCreator}
            handleToggle={toggleEditProfileModal}
            isLoading={disableModal}
            error={editProfileError}
          />
          <MonetizationModal
            open={openMonetizationModal}
            handleToggle={toggleMonetizationModal}
            error={albumError}
          />
          <AlbumList albums={albums}></AlbumList>
        </section>
      )}

      <div id="creator-monetization">
        {user.stripeAccountId ? (
          <>
            <h2>Update monetization details</h2>
            <button
              className="btn normal-case mr-3"
              onClick={() => redirectToStripeAccountLink(true)}
            >
              Update your info in Stripe
            </button>
          </>
        ) : (
          <>
            <h2>Setup monetization</h2>
            <p>Setup your Stripe account below to start earning payouts</p>
            <button
              className="btn normal-case mr-3"
              onClick={() => redirectToStripeAccountLink(false)}
            >
              Continue
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CreatorDashboard;
