import { Link } from "react-router-dom";
import Modal from "../../../../components/Modal";
import { useEffect, useState } from "react";

type Props = {
  open: boolean;
  followList: Creator[];
  handleToggle: () => void;
  handleFollowClick: (creatorId: string) => void;
  showFollowButtons: boolean;
};

type Creator = {
  id: string;
  name: string;
  profileImage: string;
  userIsFollowing: boolean;
};

export const FollowingListModal = ({
  open,
  followList,
  handleToggle,
  handleFollowClick,
  showFollowButtons,
}: Props) => {
  return (
    <Modal open={open} onClose={handleToggle}>
      <div className="p-4">
        <h3 className="font-bold text-lg mb-4">Following</h3>
        {followList.length === 0 ? (
          <p>You are not following any creators.</p>
        ) : (
          <ul>
            {followList.map((creator) => (
              <li
                key={creator.id}
                className="flex items-center justify-between mb-4"
              >
                <div className="flex items-center">
                  <img
                    src={creator.profileImage ?? "/profile.webp"}
                    alt={creator.name}
                    className="w-10 h-10 rounded-full mr-4"
                  />
                  <Link to={`/creator/${creator.id}`}>
                    <p className="font-semibold hover:underline">
                      {creator.name}
                    </p>
                  </Link>
                </div>
                {showFollowButtons && (
                  <button
                    onClick={() => handleFollowClick(creator.id)}
                    className={`btn btn-sm btn-neutral text-white normal-case cursor-pointer ${creator.userIsFollowing ? "" : "invert"}`}
                  >
                    {creator.userIsFollowing ? "Unfollow" : "Follow"}
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Modal>
  );
};
